import React from 'react'
import { useNavigate } from 'react-router'

import { Iinfo } from '../../types/interface'
import { Card, Typography, Box, Button } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface Iprops {
    info: Iinfo | undefined
    title: string
    subtitle: string
    image: string
    cardTitle: string
    cardSubtitle: string
    cardInformation: string
    button_link: string
    button_title: string
    position: string
    id: string
}
const FrontpageInformation = (props:Iprops) => {
    const { 
        info,
        title,
        subtitle,
        image,
        cardTitle,
        cardSubtitle,
        cardInformation,
        position,
        button_link,
        button_title,
        id
    } = props

    const navigate = useNavigate()
    const matches = useMediaQuery('(max-width:690px)');
    

    const styleCardMobile = {        
        alignSelf: position === "left" ? "flex-end" : "flex-start",
        mt:"1rem",
        p:"2rem",
        width:"80vw",/*  height:"fit-content", */
        backgroundColor:"secondary.dark"
    }
    const styleCard = {
        position: "absolute",
        right: position === "left" ? "15%" : "auto", bottom:0, top:0, left: position === "left" ? "auto" : "15%",
        margin:"auto",
        p:"2rem",
        width:"20vw", height:"fit-content",
        backgroundColor:"secondary.dark"
    }
    const imageStyle = {
        width:"80vw",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        alignSelf:position !== "left" ? "flex-end" : "flex-start",
    }
  return (
    <Box component="section" pt={title === "" ? "0rem" : "5rem"} className="flex__center-c" id={id}>

        {/* Title */}
        <Typography variant='h2' textAlign="center" color="secondary">
            {title}
        </Typography>

        {/* Subtitle */}
        <Typography variant='subtitle1' textAlign="center" color="black" maxWidth={"50vw"}>
            {subtitle}
        </Typography>

        {/* Image & textcard */}
        <Box position="relative" width="100vw" mt="4rem" justifyContent={position === "left" ? "flex-start" : "flex-end" } display="flex" flexDirection={matches ? "column" : "row"}>

            <img src={image} style={imageStyle} alt="frontpageinfomration" />

            <Card 
                sx={matches ? styleCardMobile : styleCard}
            >
                <Typography variant='h4'sx={{borderLeft:"2px solid #ffffff"}} mb="1rem">
                    {cardSubtitle}
                </Typography>

                <Typography variant='h3' textAlign="left" mb="0.5rem" color="secondary.main">
                    {cardTitle}
                </Typography>

                <Typography variant='subtitle1' textAlign="left" ml="1rem">
                    {cardInformation}
                </Typography>

                {button_title && 
                <Button /* download={button_link || "" } */ href={button_link || "" } target="_blank" rel="noopener" endIcon={ <ArrowForwardIosIcon />  } size='small'> 
                    {button_title}
                </Button>
                }


             

            </Card>

        </Box>

    </Box>
  )
}

export default FrontpageInformation