import Box from '@mui/material/Box'
interface Iprops {
    height: string
    propImage: string
}

const imageStyle = {
    width: "100vw",    
}

const ImageHeader = (props:Iprops) => {
    const { height, propImage } = props
    return (
        <Box component="section"  p="2rem 0" id="imageheader">
            <img style={imageStyle}  src={propImage} alt="header" className="objectfitcontain"/>   
        </Box>
    )
}

export default ImageHeader