import { useRef , useLayoutEffect} from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Mui
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';

import { Iinfo, IeditInfo } from '../../types/interface'
gsap.registerPlugin(ScrollTrigger)

const NavbarStyle = {
    //position: "fixed",
    //bottom: 0, left:0,right:0,
    //margin:"0 auto",
    height: "25rem",   
    //zIndex: 5,   
    '& .MuiBreadcrumbs-separator':{
        color: "secondary.main"
    
    }
   

}






interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const Footer = (props:Iprops) => {
    const { info, editInfo } = props

    const header_ref = useRef(null)

   

    return (
        <Box  component="footer" ref={header_ref} sx={NavbarStyle} className="flex__center-c" color="text.primary" id="footer">
            
            <Typography variant='h2' color="secondary">{editInfo?.Footer?.Title}</Typography>
            <Typography variant='h5' color="text.secondary">{editInfo?.Footer?.Address}</Typography>

            <Box className="flex__center-r" sx={{mt:"1rem"}}>
                <IconButton sx={{}} href={ editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener">
                    <InstagramIcon fontSize='large' color='secondary' />
                </IconButton>

                <IconButton sx={{}} href={editInfo?.Footer?.Facebook_Link || "" } target="_blank" rel="noopener">
                    <FacebookIcon fontSize='large' color='secondary' />
                </IconButton>

                <IconButton sx={{}} href={editInfo?.Footer?.Twitter_Link || "" } target="_blank" rel="noopener">
                    <TwitterIcon fontSize='large' color='secondary' />
                </IconButton>

                <IconButton sx={{}} href={editInfo?.Footer?.LinkedIn_Link || "" } target="_blank" rel="noopener">
                    <LinkedInIcon fontSize='large' color='secondary' />
                </IconButton>
            </Box>

            <Typography variant='h6' color="text.secondary">{info?.Footer?.Designed_By}</Typography>
            
        </Box> 
    )
}

export default Footer