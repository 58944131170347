import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'






const serverAddress = process.env.NODE_ENV === "production" ? "https://api.heineras.se" : "http://localhost:5000"


interface decodedAccTokenInit  {
    exp: number
    iat: number
    roles: string
    tokenId: string
    userId: string
}
export const GetGallery = createAsyncThunk(
    "get/GetGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetInfo = createAsyncThunk(
    "get/GetInfo",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getinfo`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const PostContact = createAsyncThunk(
    "post/PostContact ",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/postcontact`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetMenu = createAsyncThunk(
    "get/GetMenu",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/menu/menu.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetLunch = createAsyncThunk(
    "get/GetLunch",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/menu/lunch${info?.week}.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetEvent = createAsyncThunk(
    "get/GetEvent",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/event/event.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})


const initalStateValue = { AccToken:"" , user:{}, status: "inital", refreshingToken: false, info:{}}


const postSlice = createSlice({
    name:"fetchData",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {       
        builder.addCase(GetInfo.fulfilled,(state, action) => {
            state.info = action?.payload?.body?.data
            
        })
      
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = postSlice

export default reducer

 