import { useLayoutEffect, useRef, useState, useEffect} from 'react'
import { gsap } from 'gsap'


import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'



import { MainLoading } from '../../components'
import { Iinfo, IeditInfo } from '../../types/interface'




interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const WelcomepageInfo = (props:Iprops) => {
    const { info, editInfo } = props
    const [loading, setLoading] = useState(false)
    const header_ref:any = useRef(null)



    //* Handles Resize  */
    const [mobileScreen, setMobileScreen] = useState<boolean>(false)
    const handleResize = () => {        
        let width =  header_ref.current ? header_ref?.current?.offsetWidth : 0
       
        if(width < 600){
            setMobileScreen(true)
        }else{
            setMobileScreen(false)
        }       
    }
    useEffect(() => {
        handleResize()
    },[])
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    
    
    
        
    const imageStyle = {
        width: "100vw"
    }


    return (
        <Box id='welcomepageinfo' component="section" className="flex-c" ref={header_ref} sx={{position:"relative", gap:"2rem"}} >

            {/* Image */}
            {/* <img  style={imageStyle} src={info?.Welcomepage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>    */}

            <Box mt="5rem">
                {/* title */}
                <Box component="header" mb="5rem">
                    <Typography variant='h1' color="secondary" 
                        sx={{
                            fontSize:"10vmin",            
                            "@media (max-width:600px)": {
                                fontSize:"10vmax",
                            } 
                        }} 
                    >
                        {info?.WelcomepageInfo?.Title} 
                    </Typography>
                    <Typography variant='h5' color="secondary" sx={{}} ml="5rem">
                        {info?.WelcomepageInfo?.SubTitle} 
                    </Typography>
                        
                    <Typography variant='h4' color="primary.main" sx={{textAlign:"left"}} mb="1rem"></Typography>
                </Box>

                {/* Body / => img & main info */}
                <Box className='flex__center-r flex__r-c' sx={{justifyContent:"space-around",gap:"1rem"}}>

                        
                        <img 
                            //src={`https://${process.env.REACT_APP_CDN_URL}/${event?.images[0]}`}
                            src={info?.WelcomepageInfo?.Image}
                            alt={"test"}
                            style={{height: '25vmax', width: '25vmax', objectFit:"cover", borderRadius: "4px",
                            boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                        
                        />
                        


                    {/* about text / opentimes */}
                    <Box className="flex__center-c" maxWidth={mobileScreen ? "90%" : "50%"}  alignItems="flex-start">

                        {/* About */}
                        <Typography variant='h4' color="primary.main" sx={{textAlign:"left"}} mb="1rem">
                            {info?.WelcomepageInfo?.Description}                            
                        </Typography>

                        <Typography variant='h3' color="secondary.main">
                            {info?.WelcomepageInfo?.OpenTimes_Text}
                        </Typography>




                        <Box className="flex__center-c" alignItems="flex-start" mt="1rem" minWidth="50%" ml="2rem" >

                            <Typography variant='h3' color="primary.main" >
                                {info?.WelcomepageInfo?.Lunch_Text}
                            </Typography>                            

                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Day1}
                                </Typography>      
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Time1} 
                                </Typography>                            
                            </Box>
                            <Box display="flex" justifyContent="space-between" mb="1rem" width="100%">
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Day2} 
                                </Typography>      
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Time2} 
                                </Typography>                            
                            </Box>


                            <Typography variant='h3' color="primary.main">
                                {info?.WelcomepageInfo?.Dinner_Text} 
                            </Typography>                            

                            <Box display="flex" justifyContent="space-between" mb="1rem" width="100%">
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Day3} 
                                </Typography>      
                                <Typography variant='h4' color="primary.main">
                                    {editInfo?.Footer?.Time3} 
                                </Typography>                            
                            </Box>
                            
                        </Box>
                        







                    </Box>
                    



                </Box>


            </Box>           



            
            {/* Title */}
         {/*    <Typography id="welcomepage-title" variant='h1' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.Title}
            </Typography> */}
            

            {/* Buttons */}
            {/* <Box id="welcomepage-buttons" className="flex__center-r" sx={{gap:"2rem"}}>
                <Button variant='outlined' color='info' sx={{borderWidth:"0.15rem"}} >
                    {info?.Welcomepage?.Menu_Button}
                </Button>

                <Button variant="contained">
                    {info?.Welcomepage?.Reservation_Button}
                </Button>
            </Box> */}


            {/* Message / Other */}
            {/* <Typography id="welcomepage-other" variant='h4' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.SubTitle}
            </Typography> */}

           

            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            

            
        </Box>
    )
}

export default WelcomepageInfo