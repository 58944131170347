import { useEffect, useState, useRef } from 'react'
import gsap from 'gsap'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { Container, Typography } from '@mui/material';
import FloodIcon from '@mui/icons-material/Flood';

import { Iinfo,IeditInfo } from '../../types/interface';


interface Iprops {
        loading:boolean,
        info: Iinfo | undefined
    }
const MainLoading = (props:Iprops) => {
    const { loading, info } = props;
   
    
    const loading_ref = useRef(null)

    const [stateLoading, setStateLoading] = useState(loading)
    useEffect(() => {
      setStateLoading(loading)
    
      return () => {
        
      }
    }, [loading])



    
    
    return (
        <Container ref={loading_ref}>    
            <Backdrop
                sx={{ backgroundColor: "#2a2a2a", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , display:"flex", flexDirection:"column", width: "100vw"}}
                open={stateLoading}                
            >
                <Typography color="primary">
                    {info?.MainLoading?.Title}
                </Typography>

                <FloodIcon sx={{margin:"2rem 0"}} color="primary"/>

                <CircularProgress color="primary" />
                {/* <Box sx={{ width: '50%' }}>
                    <LinearProgress color='primary'/>
                </Box> */}
            </Backdrop>
        </Container>
    );
}

export default MainLoading