import { useLayoutEffect, useRef, useState} from 'react'
import { gsap } from 'gsap'


import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'



import { MainLoading } from '../../components'
import { Iinfo } from '../../types/interface'




interface Iprops {
    info: Iinfo | undefined
}
const Welcomepage = (props:Iprops) => {
    const { info } = props
    const [loading, setLoading] = useState(true)
    const header_ref = useRef(null)

 

       


    
    
    
        
    const imageStyle = {
        width: "100vw"
    }


    return (
        <Box component="section" className="flex-c" ref={header_ref} sx={{ position:"relative", gap:"2rem"}} >

            {/* Image */}
            <img  style={imageStyle} src={info?.Welcomepage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

            
            {/* Title */}
         {/*    <Typography id="welcomepage-title" variant='h1' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.Title}
            </Typography> */}
            

            {/* Buttons */}
            {/* <Box id="welcomepage-buttons" className="flex__center-r" sx={{gap:"2rem"}}>
                <Button variant='outlined' color='info' sx={{borderWidth:"0.15rem"}} >
                    {info?.Welcomepage?.Menu_Button}
                </Button>

                <Button variant="contained">
                    {info?.Welcomepage?.Reservation_Button}
                </Button>
            </Box> */}


            {/* Message / Other */}
            {/* <Typography id="welcomepage-other" variant='h4' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.SubTitle}
            </Typography> */}

           

            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            

            
        </Box>
    )
}

export default Welcomepage