import { useState, useLayoutEffect, useRef, useEffect }from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
//import { Link } from "react-scroll";

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


// REACT ICONS


// SELF IMPORTS 
import { ReactComponent as Logo } from '../../assets/afekcii_grill.svg'
import { LanguageSelector } from '../../components/index'

// STYLES
const NavbarStyle = {
    position: "fixed",
    left: 0, right: 0, top: 0,
    height: "80px",
    width:"100vw",
    justifyContent: "space-around",  
    zIndex: 5,    

    bgcolor:"#ffffff",
    boxShadow:" rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"   
}
const NavbarStyleMobile = {
    position: "fixed",
    left: 0, right: 0, top: 0,
    height: "80px",
    width:"100vw",
    justifyContent: "space-between",  
    zIndex: 5,    
    
}

gsap.registerPlugin(ScrollTrigger);

const Navbar2 = (props:any) => {    //TODO CHANGE any
    const { info }  = props  
   
    const navigate = useNavigate()
    const location = useLocation()
   
    const navbar_ref = useRef(null)
    const mobile_navbar_ref = useRef(null)
    const mobile_navbar_logo_ref = useRef(null)
    

    //* INTERFACE
    

    //* VARIABLES
    const [toggleNavbarMenu, setToggleNavbarMenu] = useState<boolean>(false)
    const [mobileNavbarStyle, setMobileNavbarStyle] = useState<any>({pointerEvents:"none",opacity:0,right:"-1000px"})
    const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})

   
    
    //* FUNCTIONS
    const navigateClick = (location=""):any => {
        if(location){      
        navigate(location);      
        //window.scrollTo(0,0);
        }
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px",})   
        setSpanStyle(prev => ({...prev,
        span1:{},
        span2:{},
        span3:{}

        })) 
        
        setToggleNavbarMenu(false)
    }
    const ToggleMobileMenu = (toggle:boolean) => {
        if(toggle){
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px"})   
        setSpanStyle(prev => ({...prev,
            span1:{},
            span2:{},
            span3:{}

        })) 
            
        }else{
        setMobileNavbarStyle({pointerEvents:"auto",opacity:1,right:"0"})   
        setSpanStyle(prev => ({...prev,
            span1:{transform: "rotate(45deg) translate(-3px, -1px)", background: "#ffffff"},
            span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" , background: "#ffffff"},
            span3:{transform: "rotate(-45deg) translate(0, -1px)" , background: "#ffffff"}

        })) 
        }
        setToggleNavbarMenu(!toggle)
    }
   
       


    //* GSAP+
    // Fades the navbar when scrolling down and shows when scrolling up
    useLayoutEffect(() => {

        ScrollTrigger.saveStyles(navbar_ref.current);
        let mm = gsap.matchMedia();
        mm.add("(min-width: 690px)",  () => {
           
            const ctx = gsap.context(()=>{

            const showNav = gsap.fromTo(navbar_ref.current,{
                autoAlpha: 0
            },{
                autoAlpha: 1,
                duration:0.4
            }).progress(1)

                
            
            ScrollTrigger.create({                   
                start: "top top", 
                end: "max",           
               //markers:true,         
                onUpdate: (self) => {
                    self.direction === -1 ? showNav.play() : showNav.reverse()
                }
                })                      
            },navbar_ref)
        
            return () => {
            ScrollTrigger.refresh()
            ctx.revert()
            };


        })
        mm.add("(max-width: 690px)",  () => {
            const ctx = gsap.context(()=>{

                const showNav = gsap.fromTo(mobile_navbar_ref.current,{
                    autoAlpha: 0
                },{
                    autoAlpha: 1,
                    duration:0.4
                }).progress(1)

      
           
                    
                
                ScrollTrigger.create({                   
                    start: "top top", 
                    end: "max",             
                     
                    onUpdate: (self) => {
                        self.direction === -1 ? showNav.play() : showNav.reverse()
                  
                    }
                    })                      
                },mobile_navbar_ref)
            
                return () => {
                    ScrollTrigger.refresh()
                    ctx.revert()
                };
        })



        return () => {
           
            mm.revert()
            };
    }, [])
    


    //* Main Return */
    const matches = useMediaQuery('(max-width:690px)');
    
    const navbardata = [
        {
            link: info?.Navbar?.Navigation_0?.link,
            title: info?.Navbar?.Navigation_0?.title
        },
        {
            link: info?.Navbar?.Navigation_1?.link,
            title: info?.Navbar?.Navigation_1?.title
        },
        {
            link: info?.Navbar?.Navigation_2?.link,
            title: info?.Navbar?.Navigation_2?.title
        },
        {
            link: info?.Navbar?.Navigation_3?.link,
            title: info?.Navbar?.Navigation_3?.title
        }
    ]
    const locationPathStyle = {     
        fullColor: {
            color: "secondary.main"
        },
        fadeColor: {
            color: "secondary.contrastText"
        }        
    }
    



    // if and id is set to the url, then scroll to that element
    useEffect(() => {
        const elementId = location.hash.substring(1); // Remove the leading '#' from the URL hash
        scrollToElement(elementId);
    }, [location]);    
    const scrollToElement = (elementId:string) => {
        const element = document.getElementById(elementId);
        
        if (element) {
            
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            },500)
            
        }else{
            window.scrollTo(0,0)
        }
    };





    //* Navbar button for each navigation link 
    const NavButton = (path:string, title:string,index:number) => {           
    
        return(
         
            <Box className='flex' key={`NavButton${index}`}>
                    
                {index === 0  && <Divider orientation='vertical' variant='middle' flexItem/>}
                <Button sx={{
                    '&:hover': {
                        backgroundColor: '#ffffff00',                        
                    },
                    '&:hover .MuiTypography-root': {                        
                        color:"secondary.main"
                    },
                    
                }} onClick={()=>navigate(path)} >
                    <Typography variant='h4' color="secondary.contrastText" sx={ path === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor }>{title}</Typography>   
                </Button>
              <Divider orientation='vertical' variant='middle' flexItem/>
              
            </Box>
          
        )
    }

    //* Navbar button for each navigation link 
    const NavButtonMobile = (path:string, title:string,index:number) => {           
        return(

            <Button variant="text" color='secondary'
                onClick={()=>navigateClick(path)}
                key={`NavButton${index}`}
            >
                <Typography variant='h4' color="secondary" >{title}</Typography>   
                
            </Button>
            
            
        )
    }


  
   
    if(matches){ /* Mobile */
        return (
            <Box  className="flex__center-r mobile-navbar" sx={NavbarStyleMobile} ref={mobile_navbar_ref}>

                
                
                    <Box flex={1}>

                    </Box>
                


                {/* TITLE BUTTON */}
                <Box flex={1} className="flex__center-c">
                    <Logo  width={matches ? "12vmax" : "12vmin"} height={matches ? "12vmax" : "12vmin"} className='container__icon-svg-nobg' onClick={()=>navigate("/")}/>                 
                </Box>
                
                    
                {/* Hamburger Menu */}
                <Box className='navbar-small__icon flex__center-c' onClick={()=>ToggleMobileMenu(toggleNavbarMenu)} sx={{flex:1,width:"50px",height:"50px",zIndex:5}}>
                    <span style={spanStyle.span1}></span>
                    <span style={spanStyle.span2}></span>
                    <span style={spanStyle.span3}></span>               
                </Box>      
                

                {/* Mobile Navbar */}
                <Box className='navbar-small flex__center-c' style={mobileNavbarStyle} bgcolor={"primary.dark"} >                                
                 

                    {/* TITLE BUTTON */}
                    <Box onClick={()=>navigateClick("/")} sx={{}}>                    
                        <Logo width={matches ? "12vmax" : "12vmin"} height={matches ? "12vmax" : "12vmin"} className='container__icon-svg-nobg' />        
                    </Box>
                    
                    {/* NAVIGATION MENU */}                    
                    {navbardata?.map((navitem, i)=> (
                        NavButtonMobile(navitem.link, navitem.title,i)
                    ))}                                                                         
                    
                    <Button variant="outlined" color='secondary' sx={{borderWidth:"0.2rem"}}
                        onClick={()=>navigateClick(info?.Navbar?.Navigation_9?.link)}
                    >
                        <Typography variant='h4' color="secondary" >{info?.Navbar?.Navigation_9?.title}</Typography>   
                        
                    </Button>

                    <IconButton href={info?.Footer?.Instagram_Link} target="_blank" rel="noopener" sx={{width:"50px",height:"50px"}} color='info'>
                        <InstagramIcon fontSize='large' fill='#ffffff !important' />
                    </IconButton>   

                </Box>


               

            </Box>
        )
    }else {    
        return (
            <Box className='flex__center-r desktop-navbar' ref={navbar_ref} sx={NavbarStyle}>
            
                {/* TITLE BUTTON */}
                <Box className="flex__center-c" onClick={()=>navigate("/")} ml="1rem" sx={{cursor:"pointer"}}>                          

                    <Logo width={matches ? "12vmax" : "12vmin"} height={matches ? "12vmax" : "12vmin"} className='container__icon-svg-nobg' />

                </Box>


                {/* NAVIGATION MENU */}
                <Box className='flex__center-r' sx={{}}>                
                    {navbardata?.map((navitem, i)=> (
                        NavButton(navitem.link, navitem.title,i)
                    ))}                                                                         
                </Box>


                {/* Social media icons */}
                <Box className='flex__center-r'>

                <Button sx={{
                    '&:hover': {
                        backgroundColor: '#ffffff00',                        
                    },
                    '&:hover .MuiTypography-root': {                        
                        color:"secondary.main"
                    },
                    
                    }} onClick={()=>navigate(info?.Navbar?.Navigation_4?.link)} >
                    <Typography variant='h6' color="secondary" sx={ info?.Navbar?.Navigation_4?.link === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor }>{info?.Navbar?.Navigation_4?.title}</Typography>   
                </Button>
                        
                    

                              

                    

                </Box>
                                             
            </Box>
        )
    }
    }

    export default Navbar2