import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


const serverAddress = process.env.NODE_ENV === "production" ? "https://api.klippanskonstcafe.se" : "http://localhost:5000"


//* Reservation
export const PostReservation = createAsyncThunk(
    "post/PostReservation",
    async(info: any,{ getState } ) => {
    try{
        const res = await axios.post(`${serverAddress}/postreservation`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetReservationTimes = createAsyncThunk(
    "get/GetReservationTimes",
    async(info: any,{ getState } ) => {
    try{
        const res = await axios.get(`${serverAddress}/getreservationtimes/?type=${info?.type}&people=${info?.people}&date=${info?.date}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetReservationDates = createAsyncThunk(
    "get/GetReservationDates",
    async(info: any,{ getState } ) => {
    try{
        const res = await axios.get(`${serverAddress}/getreservationdates/?type=${info?.type}&dateNow=${info?.dateNow}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})


interface reservationinit {     
    fetch:boolean
    times: number []
}

const initalStateValue:reservationinit = {  
    fetch:false,
    times:[]     
}


const postSlice = createSlice({
    name:"reservation",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {                
        builder.addCase(GetReservationDates.fulfilled, (state, action) => {    
           
            let filteredRes:any = []
            for (let i = 0; i < action?.payload?.body?.data?.length; i++) {
                const reservedDate:any = action?.payload?.body?.data[i];
                filteredRes.push( {date: parseInt(reservedDate.sk)} )
                
            }                                              
                    
            return {fetch:true, times: filteredRes }
        })              
    }
})

const { reducer } = postSlice

export default reducer

 