import { configureStore, combineReducers } from "@reduxjs/toolkit";
import fetchReducer from './slices/fetchReducer'
import reservationReducer from './slices/reservationReducer'
import shopReducer from './slices/shopReducer'
import cartReducer from './slices/cartReducer'
import { createStateSyncMiddleware, withReduxStateSync,initStateWithPrevTab } from 'redux-state-sync';
const reducer = combineReducers({   
    fetchReducer: fetchReducer,
    reservationReducer: reservationReducer,
    shopReducer: shopReducer,
    cartReducer: cartReducer

})
const rootReducer = withReduxStateSync(reducer);
const extraMiddlewares = [createStateSyncMiddleware({    })]
const store = configureStore({
    reducer:rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [/* if you needed */],
        },
      }).concat(extraMiddlewares),
})

initStateWithPrevTab(store);

export default store

