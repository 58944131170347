import { createSlice , current} from '@reduxjs/toolkit'

//localStorage.setItem("cart",JSON.stringify([{["id"]:"123123",a:123123,quantity:1}, {["id"]:"1232323",quantity:1},{["id"]:"454545",l:123123132123132,quantity:1}])) 
const getTotalprice = () =>{
    const cart = getCart()   
    let count = 0
    if(cart.length === 0){
        return 0
    }
    cart.forEach((item :any) => {
        count = item?.quantity*item.price + count
    })             
    return count 
}
const getNumberItems = () =>{
    const cart = getCart()   
    let count = 0
    if(cart.length === 0){
        return 0
    }
    cart.forEach((item :any) => {
        count += item.quantity
    })             
    return count
}
const getCart = () => {    
   
    const storageCart = localStorage.getItem("cart")
    
    
    if(!storageCart){
        return []        
    }else {
        return JSON.parse(storageCart)
    }
}
const Cart = createSlice({
    name:"cart",
    initialState: {cart:getCart(),length:getNumberItems(),totalprice:getTotalprice()},
    reducers: {       
        addToCart(state: any, action){
            try {                          
                console.log("action",action.payload)
                const stateCart: any = state.cart
                const storageCart: any = getCart() 
                const storagePayload = {
                    "id": action.payload["id"],
                    quantity: 1,
                    title: action.payload["title"],
                    price: action.payload["price"],
                    images: action.payload["images"],
                    size: action.payload["size"],
                    color: action.payload["color"]
                }


                if(stateCart.length === 0) {
               
                    localStorage.setItem("cart",JSON.stringify([storagePayload])) 
                    state["cart"] = [storagePayload]
             
                    state["length"] = getNumberItems()
                    state["totalprice"] = getTotalprice()
                    
                    return state
                }
               
                // adds 1 more on quantity
                // if its still stock(quantity) left then increase
                const indexFound = stateCart.findIndex((e: any) => e["id"] === action.payload["id"])                                
                if(indexFound >= 0 ){         
                  
                    
                    stateCart[indexFound]["quantity"] += 1
                    storageCart[indexFound]["quantity"] += 1
                 
                                               
                }else {
                  
                    stateCart.push(storagePayload)
                    storageCart.push(storagePayload)
                }
             
                localStorage.setItem("cart",JSON.stringify(storageCart))   
                state["cart"] = stateCart
                state["length"] = getNumberItems()
                state["totalprice"] = getTotalprice()
             
            return state
            } catch (error) {
                   
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        removeQuantityFromItem(state, action){
            try {                            
                const storageCart: any = getCart()         
                const stateCart: any = state.cart                         
                const indexFoundStorage = storageCart.findIndex((e: any) => e["id"] === action.payload["id"])
                const indexFoundState = stateCart.findIndex((e: any) => e["id"] === action.payload["id"])

                if(indexFoundStorage >= 0 && indexFoundState >= 0){                
                    if(storageCart[indexFoundStorage]["quantity"] === 1 && stateCart[indexFoundStorage]["quantity"] === 1){
                        storageCart.splice(indexFoundStorage, 1)
                        stateCart.splice(indexFoundState, 1)
                    }else{
                        storageCart[indexFoundStorage]["quantity"] -= 1
                        stateCart[indexFoundState]["quantity"] -= 1
                    }
                    localStorage.setItem("cart",JSON.stringify(storageCart))   
                    state["cart"] = stateCart
                    state["length"] = getNumberItems()
                    state["totalprice"] = getTotalprice()
                }
                return state
            } catch (error) {
                   
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },                
        removeItemCart(state, action){
            try {                            
                const storageCart = getCart()         
                const stateCart = state.cart                         
                const indexFoundStorage = storageCart.findIndex((e: any) => e["id"] === action.payload["id"])
                const indexFoundState = stateCart.findIndex((e:any) => e["id"] === action.payload["id"])
                if(indexFoundStorage >= 0 && indexFoundState >= 0){                
                    storageCart.splice(indexFoundStorage, 1)            
                    stateCart.splice(indexFoundState, 1)
                }
                localStorage.setItem("cart",JSON.stringify(storageCart))   
                state["cart"] = stateCart
                state["length"] = getNumberItems()
                state["totalprice"] = getTotalprice()
                return state
            } catch (error) {
                  
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        clearCart(state, action){
            try {                            
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
                return state
            } catch (error) {
                 
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        
    }
})

const { reducer } = Cart

export const { addToCart, removeQuantityFromItem, removeItemCart, clearCart } = Cart.actions

export default reducer