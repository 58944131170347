import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'






const serverAddress = process.env.NODE_ENV === "production" ? "https://api.heineras.se" : "http://localhost:5000"


export const PaymentReqSwish = createAsyncThunk(
    "post/PaymentReqSwish",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/paymentreqswish`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})

export const PaymentReqStripe = createAsyncThunk(
    "post/PaymentReqStripe",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/paymentreqstripe`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})
export const GetQrSwish = createAsyncThunk(
    "get/GetQrSwish",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getqrswish/?token=${info?.token}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})
export const GetSwishStatus = createAsyncThunk(
    "get/GetSwishStatus",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`${serverAddress}/getswishstatus/?id=${info.id}`);
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})

export const GetShopItems = createAsyncThunk(
    "get/GetShopItems",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getshopitemsclient`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})

const initalStateValue = { status: "inital", items: [], details:[]}


const postSlice = createSlice({
    name:"shop",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {       
        builder.addCase(PaymentReqSwish.fulfilled,(state, action) => {
           
            
        })
        builder.addCase(GetShopItems.fulfilled,(state, action) => {
            
            if(action?.payload?.statusCode === 200){
                const foundItems = action.payload?.body?.data.filter((item:any) => item.sk === "item")
                state.items = foundItems
                
            }
            
        })
      
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = postSlice

export default reducer

 