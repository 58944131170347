import { useState, useEffect, Fragment, useCallback} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation,ScrollRestoration } from 'react-router-dom'


/* dashboard */
import { responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material/styles';

//* REDUX */
import  store  from './store'
import { useDispatch } from 'react-redux'
import { GetGallery,GetMenu } from './slices/fetchReducer'

/* Self Imports  */
import {  Navbar2, Footer } from './components/index'
import { Welcomepage,WelcomepageInfo, ImageHeader, FrontpageInformation, DrinksFirstPage, Menu, MenuLeft, Reservation } from './containers/index'
import { Iinfo, IeditInfo } from './types/interface'




let theme = createTheme({    
    palette: {
        primary: {
            main: '#292929d8',                       
            dark: '#181818ff' ,
            light: "#ffffff",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#d7a33c',
            light:"#a9546b",
            dark:"#3c70d7",
            contrastText:"#000000"
            
        },
        info: {
            main: "#ffffff",
            dark:"#000000",
        },
        text: {
            primary: "#ffffff",
            secondary: "#000000"
        }
        
    },
    typography: {    
        h1: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"5vmax",
            } 
        },
        h2: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"4vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"4vmax",
            } 
        },
        h3: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"3vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"3vmax",
            }  
        },
        h4: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"2vmax",
            } 
        },
        h5: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            } 
        },
        h6: {            
            color: 'text.secondary',
            textAlign: 'center',
            fontSize:"1.2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.2vmax",
            } 
        },
        subtitle1: {
            color:"text.secondary",
            textAlign: 'center',
            fontSize:"1vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1vmax",
            }  
        },
        subtitle2: {         
            color:"text.secondary",   
            textAlign: 'center',
            fontSize:"0.8vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"0.8vmax",
            } 
        },      
        button: {            
            textAlign: 'center',
            fontSize:"1vmax",
            fontWeight:"400" 
        },
     
      },
    components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1: 'h1',
              h2: 'h2',
              h3: 'h3',
              h4: 'h4',
              h5: 'h5',
              h6: 'h6',              
              subtitle1: 'p',
              subtitle2: 'h2',
              body1: 'span',
              body2: 'span',
            },
          },
        },
        MuiButton: {
            defaultProps: {          
                color:"secondary",   
                sx:{
                    fontSize:"1.2vmax",  
                    fontWeight:"400"
                }                                                             
            },
           
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                  '& .MuiOutlinedInput-root': {
                    
                    '&:hover fieldset': {
                        borderColor: '#d7a33c',
                    },
                   
                  },
                },
              },
            defaultProps: {          
                //color:"info",     
                sx:{
                
                    input: {
                        color:"text.secondary",   
                                              
                    },                          
                    label:{
                        color:"text.secondary",                             
                    },
                    fieldset: {
                        borderColor:"text.secondary",
                      
                    } 
                }        
            },
        },
        MuiMobileStepper:{
            defaultProps: {          
                color:"text.secondary !important",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"text.secondary !important",
                    }
                }
            }
        },
        MuiStepLabel:{
            defaultProps: {                                        
                sx:{                    
                    '& .MuiStepLabel-label.Mui-active':{
                        color:"secondary.dark",
                    },
                    '& .MuiStepLabel-label.Mui-completed':{
                        color:"secondary.main",
                    },
                    
                }
            }
        },
      
        MuiRadio:{
            defaultProps: {          
                color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                       // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }/* ,
                    '& .Mui-colorPrimary':{
                        color:"#ffffff"
                    },
                    '& .Mui-colorSecondary':{
                        color:"#ffffff"
                    } */
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                   
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                   
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    },
});
theme = responsiveFontSizes(theme);

const App = () => {
    const defaultTheme = theme;

    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
 


    //* Get Info */
    interface ObjectStrings {[key:string]:string}
    interface ObjectAll {[key:string]: ObjectStrings}
    const [info, setInfo] = useState<Iinfo>()
    useEffect(() => {
        fetch('/info/info.json')
            .then(res => res.json())
            .then((res) => {                       
                setInfo(res)
            })            
        return () => {}
    }, [])

    interface Imenu { 
        title: string
        info: string        
        price: number
        category: string
    }

    const [gallery, setGallery] = useState<string []>([])
    const [editInfo, setEditInfo] = useState<IeditInfo >()
    const [eventAll, setEventAll] = useState([])
    const [frontPageId, setFrontPageId] = useState("")
    const [menu, setMenu] = useState<Imenu []>([])
    const [menuCategory, setMenuCategory] = useState<string []>([])
    const [event, setEvent] = useState({
        _id: "",
        title: "",
        info: "",
        images: [],
    })
    useEffect(() => {    

        //fetch(`/event/event.json`)
        fetch(`https://${process.env.REACT_APP_CDN_URL}/event/event.json`)
            .then(response => {return response.json()})
            .then(data => {                
                setEventAll(data?.Event)
                const tempevent =  data?.Event.filter((obj:ObjectAll) => obj._id === data?.FrontPage)                
                setEvent(tempevent[0])
                setFrontPageId(data?.FrontPage)
            })
            .catch(err=> console.log(err))

    
            dispatch(GetGallery({}))
                .unwrap()
                .then(res => {
                    
            })


        fetch(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`)
            .then(response => {return response.json()})
            .then(data => {                             
               
                setGallery(data.Gallery)                
            })
            .catch(err=> console.log(err))


        //fetch(`https://${process.env.REACT_APP_CDN_URL}/info/editinfo.json`)
        fetch(`/info/editinfo.json`)
            .then(response => {return response.json()})
            .then((data) => {                                                             
                setEditInfo(data)                
            })
            .catch(err=> console.log(err))

          

      
           
        dispatch(GetMenu({}))
            .unwrap()
            .then(data => {
                data = {
                    Menu: [
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat2",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat3",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat2",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat3",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat1",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat2",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"title1",
                            category: "Cat3",
                            price: 100,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        
                    ],
                    Category: ["Cat1","Cat2","Cat3","Cat4","Cat5","Cat6"]
                }
                setMenu(data?.Menu) //data?.Menu
                setMenuCategory(data?.Category) //data?.MenuCategory
        })

        return () => {
        
        }

    }, [])



 

 
    


    return (
        <Router >

            <ThemeProvider theme={defaultTheme}>
            
                 
                    <Navbar2 info={info}/>

                

                        
                        <Routes >                            
                            <Route  path="" index element={
                                <Fragment>
                                 
                                    <Welcomepage info={info}  />
                                    <WelcomepageInfo info={info} editInfo={editInfo} />
                                    <ImageHeader height={"100vh"} propImage={"/images/menu1.webp"} />
                                    <FrontpageInformation 
                                        info={info} 
                                        title={"Great steaks, chops and seafood!"} 
                                        subtitle={"s a vibrant and eclectic fusion restaurant that draws inspiration from the rich and diverse culinary traditions of the African continent. This restaurant concept is dedicated to celebrating the incredible flavors, ingredients, and cultures of Africa through a contemporary and approachable dining experience. It aims to introduce guests to a unique and tantalizing journey of taste and culture."} 
                                        image={"/images/footer1.webp"} 
                                        cardTitle={"Signature Dishes"}  
                                        cardSubtitle={"Perfection is here!"} 
                                        cardInformation={"The restaurant will feature signature dishes that highlight the diversity of African ingredients and cooking styles, such as Moroccan tagines, West African jollof rice, Ethiopian injera, and South African braai (barbecue)"} 
                                        button_link={"/menu/Afekcii Grill Menu.pdf"}
                                        button_title={"Menu"}
                                        position={"left"}
                                        id={"fronpageinformation1"}
                                    />
                                    <FrontpageInformation 
                                        info={info} 
                                        title={""} 
                                        subtitle={""} 
                                        image={"/images/drinks1.webp"} 
                                        cardTitle={"African Fusion Cuisine"}  
                                        cardSubtitle={"Surf n Turf!"} 
                                        cardInformation={"Afekcii Grill offers a menu that showcases the fusion of African culinary traditions with contemporary techniques. Dishes will draw inspiration from North African, West African, East African, and South African cuisines, resulting in a diverse and exciting range of flavors."} 
                                        button_link={""}
                                        button_title={""}
                                        position={"right"}
                                        id={"fronpageinformation2"}
                                    />
                                     <FrontpageInformation 
                                        info={info} 
                                        title={""} 
                                        subtitle={""} 
                                        image={"/images/reservations1.webp"} 
                                        cardTitle={"Craft Cocktails"}  
                                        cardSubtitle={"Drinks & Chemistry"} 
                                        cardInformation={"The bar program at Afekcii Grill will offer a selection of craft cocktails infused with African flavors, such as hibiscus, baobab, and ginger, along with a diverse selection of African and locally sourced beers and wines."} 
                                        button_link={""}
                                        button_title={""}
                                        position={"left"}
                                        id={"fronpageinformation3"}
                                    />
                                  
                                    

                                </Fragment>
                            } />                                                    
                            <Route path="/reservation" element={<Reservation info={info} />} />
                            
                        </Routes>
                
                        
              
                    
                    <Footer info={info} editInfo={editInfo} />
                    

            </ThemeProvider>

        </Router>
    )
}

export default App

